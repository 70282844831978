<template>
  <v-dialog
    v-model="dialog"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
    persistent
    width="900"
  >
    <v-card flat>
      <v-card-title class="grey">
        <div class="heading-4 font-weight-bold">
          {{ isEdit ? "Edit Catalog" : "Added Catalog" }}
        </div>
        <v-spacer />
        <v-icon class="font-weight-bold" @click="close()"> mdi-close </v-icon>
      </v-card-title>
      <v-card-text class="pa-4">
        <v-dialog v-model="modalCrop" width="700px">
          <v-card>
            <v-card-text>
              <div v-show="fileUpload" class="pt-6">
                <vueCropper
                  ref="cropper"
                  :img="fileUpload"
                  :autoCrop="true"
                  :canScale="true"
                  :fixedBox="true"
                  autoCropWidth="600"
                  autoCropHeight="600"
                  class="upload-wrapper"
                ></vueCropper>
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="closeUpload">Cancel</v-btn>
              <v-btn color="success" @click="saveCrop">Save</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-form ref="form" lazy-validation>
          <v-row>
            <v-col cols="12" sm="6">
              <div
                v-if="form.image.length !== 0"
                class="align-center d-flex flex-column mb-4"
              >
                <div style="position: relative">
                  <v-img
                    :src="form.image[currentIndexImage]"
                    width="300"
                    height="300"
                    style="cursor: pointer"
                  />

                  <v-btn
                    color="red"
                    icon
                    absolute
                    small
                    :style="{
                      right: '0px',
                      top: '0px',
                    }"
                    @click="removeImage(form.image[currentIndexImage])"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
                <div class="d-flex flex-wrap" style="gap: 14px">
                  <div v-for="(image, index) in form.image" :key="index">
                    <v-img
                      :src="image"
                      :style="index !== currentIndexImage && 'opacity: 0.5'"
                      class="mt-3"
                      width="50"
                      height="50"
                      style="cursor: pointer; border: 1px solid black"
                      @click="selectImage(index)"
                    >
                      <v-icon
                        color="success"
                        fab
                        @click="editImage(image, index)"
                        >mdi-pencil-circle</v-icon
                      >
                    </v-img>
                  </div>
                </div>
              </div>

              <div
                id="upload-image"
                class="` d-flex flex-column black--text justify-center align-center `"
                @click="onButtonClick"
              >
                <v-icon large>mdi-upload</v-icon>
                <span class="body-2">Upload Image</span>
              </div>
              <input
                v-show="false"
                ref="upload"
                type="file"
                accept="image/*"
                @change="uploadFile"
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="form.plant"
                :rules="[(v) => !!v || 'Plant Name must be fill']"
                name="name-plant"
                label="Plant Name"
                dense
                outlined
                maxlength="255"
              />
              <v-text-field
                v-model="form.price"
                :rules="[(v) => !!v || 'Price Plant must be fill']"
                name="price-plant"
                label="Plant Price"
                dense
                outlined
              />
              <quill-editor
                ref="myQuillEditor"
                v-model="form.description"
                :options="editorOption"
                :class="{ 'error-description-quill': formDescriptionError }"
                aria-placeholder="Description"
                @input="descriptionHandler()"
              />
              <v-select
                v-model="form.category"
                :items="categoryList"
                :rules="[(v) => !!v || 'Category must be fill']"
                item-text="key"
                item-value="key"
                label="Category"
                class="mt-5"
                dense
                outlined
              />
              <v-text-field
                v-model="form.stok"
                :rules="[(v) => !!v || 'Stock must be fill']"
                name="stock-plant"
                label="Stock"
                dense
                outlined
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider class="mb-2" />
      <v-card-actions class="d-flex justify-center">
        <v-btn
          :loading="loading"
          width="150"
          color="#04BF68"
          dark
          depressed
          @click="save()"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { isURL } from "@/utils/validate";
import { VueCropper } from "vue-cropper";

export default {
  props: {
    dialog: Boolean,
    formData: Object,
    isEdit: Boolean,
    loading: Boolean,
    categoryList: Array,
  },
  components: {
    quillEditor,
    VueCropper,
  },
  computed: {
    errorDescription() {
      return !this.form.description;
    },
  },
  watch: {
    dialog(data) {
      if (data && this.isEdit) {
        this.form = JSON.parse(
          JSON.stringify({
            ...this.formData,
            files: this.formData.image,
            oldImage: [],
          })
        );
      } else {
        this.form.oldImage = [];
        this.form.files = [];
        this.form.image = [];
        this.form.description = "";
        this.$nextTick(() => {
          this.$refs.form.reset();
          this.$refs.form.resetValidation();
        });
      }
    },
  },
  data() {
    return {
      currentIndexImage: 0,
      formDescriptionError: false,
      fileUpload: "",
      originalFile: null,
      modalCrop: false,
      isCropEdit: false,
      editIdx: null,
      form: {
        plant: "",
        price: 0,
        description: "",
        category: "",
        stok: 0,
        image: [],
        oldImage: [],
        files: [],
      },
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            ["blockquote", "code-block"],
            [{ header: 1 }, { header: 2 }],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ direction: "rtl" }],
            [{ size: ["small", false, "large", "huge"] }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
            [{ font: [] }],
            [{ align: [] }],
            ["clean"],
            ["link"],
          ],
        },
      },
    };
  },
  methods: {
    removeImage(image) {
      if (isURL(image)) {
        this.form.oldImage.push(image);
      }
      this.form.image.splice(this.currentIndexImage, 1);
      this.form.files.splice(this.currentIndexImage, 1);
      if (this.form.files.length !== 0) this.currentIndexImage = 0;
    },
    selectImage(index) {
      this.currentIndexImage = index;
    },
    descriptionHandler() {
      this.formDescriptionError = false;
      if (this.errorDescription) {
        this.formDescriptionError = true;
      }
    },
    close() {
      this.$emit("close");
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    save() {
      const isFormValid = this.$refs.form.validate();
      const isImageFill = this.form.image.length !== 0;
      if (isFormValid && isImageFill) {
        this.$emit("save", this.form);
      }
      if (!isImageFill) {
        this.snackBar(false, "Image must be fill");
      }
    },
    onButtonClick() {
      this.$refs.upload.click();
    },
    uploadFile(e) {
      if (e.target.files.length > 0) {
        if (this.form.image.length + 1 <= 5) {
          const file = e.target.files[0];
          this.originalFile = file;
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (el) => {
            this.fileUpload = el.target.result;
            this.modalCrop = true;
          };
        } else {
          this.snackBar(false, "Maximum upload of 5 images");
        }
      }
    },
    closeUpload() {
      this.fileUpload = "";
      this.originalFile = null;
      this.modalCrop = false;
      this.isCropEdit = false;
      this.editIdx = null;
    },
    editImage(file, idx) {
      this.fileUpload = file;
      this.modalCrop = true;
      this.isCropEdit = true;
      this.editIdx = idx;
    },
    saveCrop() {
      if (this.$refs.cropper) {
        this.$refs.cropper.getCropData((data) => {
          let filename = "";
          if (!this.isCropEdit) filename = this.originalFile.name;

          if (this.isCropEdit) {
            filename = this.fileUpload.split("/").pop();
            this.form.image[this.editIdx] = data;
            this.form.files[this.editIdx] = this.dataURLtoFile(data, filename);
          } else {
            this.form.image.push(data);
            this.form.files.push(this.dataURLtoFile(data, filename));
          }
          this.closeUpload();
        });
      }
    },
    dataURLtoFile(dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }

      return new File([u8arr], filename, { type: mime });
    },
    onFileChanged(e) {
      if (e.target.files.length > 0) {
        if (this.form.image.length + 1 <= 5) {
          const file = e.target.files[0];
          this.form.files.push(file);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = (el) => {
            this.form.image.push(el.target.result);
          };
        } else {
          this.snackBar(false, "Maximum upload of 5 images");
        }
      }
    },
  },
};
</script>

<style lang="scss">
#upload-image {
  border: 1px solid gray;
  border-radius: 10px;
  cursor: pointer;
  height: 70px;
}
.upload-wrapper {
  min-height: 600px;
  width: 600px;
  border-radius: 10px;
  background: var(--unnamed-color-f8f8f8) 0% 0% no-repeat padding-box;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border: 1px solid #adadad;
  opacity: 1;
  background: #fff;
  &__background {
    background: #fff;
  }
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
