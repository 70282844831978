import request from "@/utils/request";

export function getCatalog(data) {
  return request({
    method: "post",
    url: "/catalog/get",
    data,
  });
}

export function addCatalog(data) {
  return request({
    method: "post",
    url: "/catalog/add",
    data,
  });
}

export function deleteCatalog(data) {
  return request({
    method: "post",
    url: "/catalog/delete",
    data,
  });
}

export function editCatalog(data) {
  return request({
    method: "post",
    url: "/catalog/edit",
    data,
  });
}
