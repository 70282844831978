<template>
  <v-sheet>
    <v-row class="px-3">
      <v-col cols="12" md="9">
        <v-btn
          :block="$vuetify.breakpoint.xsOnly"
          depressed
          color="#04BF68"
          dark
          @click="showDialog()"
        >
          Add Catalog
        </v-btn>
      </v-col>
      <v-col cols="12" md="3">
        <v-select
          v-model="filter"
          :items="categoryList"
          :loading="loadingCategory"
          item-text="key"
          item-value="key"
          label="Filter Category"
          dense
          outlined
          clearable
          @change="getCatalogList()"
        />
      </v-col>
    </v-row>

    <v-text-field
      v-model="query.search"
      label="Search Catalog"
      dense
      outlined
      clearable
      class="px-3"
      @change="getCatalogList()"
    />

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="data"
          :loading="loading"
          :server-items-length="total"
          item-key="id"
          @pagination="pagination"
        >
          <template v-slot:[`item.image`]="{ item }">
            <v-img
              :src="item.image[0]"
              class="ma-2"
              width="50"
              height="50"
              @click="
                () => {
                  dialogImage = true;
                  urlFile = item.image[0];
                }
              "
            />
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-btn
              color="primary"
              fab
              depressed
              x-small
              class="mx-2"
              @click="showDialog(item)"
              ><v-icon>mdi-pencil-box-outline</v-icon></v-btn
            >
            <v-btn
              color="error"
              fab
              depressed
              x-small
              @click="deleteHandler(item)"
              ><v-icon>mdi-delete-forever</v-icon></v-btn
            >
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <form-dialog
      :dialog="dialogForm"
      :isEdit="isEdit"
      :formData="form"
      :categoryList="categoryList"
      :loading="loadingForm"
      @close="closeFormDialog"
      @save="saveCatalog"
    />

    <view-image
      :viewFile="dialogImage"
      :urlFile="urlFile"
      @close="dialogImage = false"
    />

    <ModalConfirm
      :dialog="dialogConfrim"
      :text="`Delete Catalog ${deleteData.plant}?`"
      :loading="loadingForm"
      type="delete"
      title="Delete Catalog"
      @action="(r) => deleteAction(r)"
    />
  </v-sheet>
</template>

<script>
import {
  getCatalog,
  addCatalog,
  deleteCatalog,
  editCatalog,
} from "@/api/admin/catalog";
import { masterCategory } from "@/api/master";
import { uploadFile, removeFile } from "@/api/file";
import { isURL } from "@/utils/validate";

import ModalConfirm from "@/components/ModalConfirm.vue";
import ViewImage from "../../components/ViewImage.vue";
import FormDialog from "./FormDialog.vue";

export default {
  components: {
    ModalConfirm,
    ViewImage,
    FormDialog,
  },
  data() {
    return {
      loading: false,
      loadingForm: false,
      dialogConfrim: false,
      dialogForm: false,
      dialogImage: false,
      search: "",
      data: [],
      deleteData: {},
      form: {},
      urlFile: "",
      viewFile: false,
      isEdit: false,
      loadingCategory: false,
      categoryList: [],
      filter: null,
      total: 0,
      query: {
        search: "",
        page: 1,
        limit: 10,
      },
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "Catalog",
          value: "plant",
        },
        {
          text: "Image",
          value: "image",
          width: 50,
        },
        {
          text: "Stock",
          value: "stok",
          width: 100,
        },
        {
          text: "Price",
          value: "price",
          width: 100,
        },
        {
          text: "Action",
          value: "action",
          align: "center",
          width: 200,
        },
      ];
    },
  },
  mounted() {
    this.getCatalogList();
    this.getMasterCategory();
    this.resetFormData();
  },
  methods: {
    pagination(item) {
      const { page, itemsPerPage } = item;
      this.query.page = page;
      this.query.limit = itemsPerPage === -1 ? 1000 : itemsPerPage;
      this.getCatalogList();
    },
    resetFormData() {
      this.form = {
        plant: "",
        image: "",
        description: "",
        category: "",
        stok: 0,
        price: 0,
      };
    },
    snackBar(isSuccess, msg) {
      this.$store.commit("SET_SNACKBAR", {
        value: true,
        text: msg,
        color: isSuccess ? "success" : "error",
      });
    },
    stringTruncate(str, length) {
      const dots = str.length > length ? "..." : "";
      return str.substring(0, length) + dots;
    },
    getMasterCategory() {
      this.loadingCategory = true;
      masterCategory({ limit: 100 })
        .then((res) => {
          const { data } = res;
          if (data.code) {
            this.categoryList = data.data.data;
          }
          this.loadingCategory = false;
        })
        .catch(() => this.snackBar(false, "failed get category data"));
    },
    closeForm(param) {
      if (param === "save") this.getCatalogList();
      this.dialogForm = false;
    },
    showDialog(item) {
      if (item) {
        this.form = item;
        this.isEdit = true;
      } else this.isEdit = false;
      this.dialogForm = true;
    },
    getCatalogList() {
      this.loading = true;
      getCatalog({ category: this.filter, ...this.query })
        .then((res) => {
          const { data } = res;
          if (data.code) {
            this.data = data.data.data;
            this.total = data.data.total;
          }
          this.loading = false;
        })
        .catch(() => (this.loading = false));
    },
    async saveCatalog(item) {
      this.loadingForm = true;
      const body = item;
      const labelMsg = this.isEdit ? "update" : "added";

      try {
        // Upload images
        if (body.files.length !== 0) {
          await Promise.all(
            body.files.map(async (file, index) => {
              if (typeof file === "object") {
                const form = new FormData();
                form.append("file", file);

                const uploadImage = await uploadFile(form);
                if (uploadImage.data.code) {
                  // check is base64 image
                  if (body.image[index].startsWith("data:")) {
                    body.image[index] = uploadImage.data.data.path;
                  }
                }
              }
            })
          );
        }

        // delete old image, if exist
        if (this.isEdit) {
          if (isURL(item.oldImage) && item.oldImage.length !== 0) {
            await Promise.all(
              item.oldImage.map(async (file) => {
                const delImage = await removeFile({ file: file });
                if (!delImage.data.code)
                  this.snackBar(false, "Failed delete image");
              })
            );
          }
        }

        // Check form action
        const { data } = this.isEdit
          ? await editCatalog(body)
          : await addCatalog(body);
        if (data.code) {
          this.closeFormDialog();
          this.snackBar(true, `Success ${labelMsg} catalog`);
        } else {
          this.snackBar(false, `Failed ${labelMsg} catalog`);
          this.resetFormData();
        }
      } catch (error) {
        console.error("saveCatalog()\n", error);
      } finally {
        this.loadingForm = false;
      }
    },
    closeFormDialog() {
      this.dialogForm = false;
      this.resetFormData();
      this.getCatalogList();
    },
    deleteHandler(item) {
      this.deleteData = item;
      this.dialogConfrim = true;
    },
    async deleteAction(param) {
      if (param) {
        try {
          const { id, image } = this.deleteData;
          this.loadingForm = true;

          const delCatalog = await deleteCatalog({ id: id });
          if (delCatalog.data.code) {
            // delete image from server
            if (isURL(image)) {
              await Promise.all(
                image.map(async (file) => {
                  const delImage = await removeFile({ file: file });
                  if (!delImage.data.code)
                    this.snackBar(false, "Failed delete image");
                })
              );
            }
            this.snackBar(true, "Success delete catalog");
          } else this.snackBar(false, "Failed delete catalog");
        } catch (error) {
          console.error("deleteAction()\n", error);
        } finally {
          this.loadingForm = false;
          this.dialogConfrim = false;
          this.getCatalogList();
        }
      } else {
        this.dialogConfrim = false;
      }
    },
  },
};
</script>
